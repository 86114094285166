import { METADATA_STORAGE } from "../decorators/header-name.decorator";
import { DateHelperService } from '../services/date-helper.service'

export function getHeaderMappingForInstance(instance: object): { [key: string]: string } {
  const className = instance.constructor.name;
  return METADATA_STORAGE[className] || {};
}

export function generateFileName(dateHelper: DateHelperService, name: string, startDate: string | null, endDate: string | null): string {
  const start = startDate ? dateHelper.parseDateStrToLocalDate(startDate) : null;
  const end = endDate ? dateHelper.parseDateStrToLocalDate(endDate) : null;

  if (start && !end) {
    return `${name}_${start}_${dateHelper.format(Date.now(), 'yyyyMMdd')}`;
  }

  return end ? `${name}_${start}_${end}` : `${name}`;
}
