import { NgModule } from '@angular/core';
import { DashboardStoreModule } from './dashboard/dashboard-store.module';
import { SettlementsStoreModule } from './settlements/settlements-store.module';

@NgModule({
  declarations: [],
  imports: [
    DashboardStoreModule,
    SettlementsStoreModule
  ]
})
export class PartnerStoreModule { }
