import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, combineLatest, merge } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Area, DailyEarningsSplit, Driver, DriverAggregate, PartnerCompanyAggregate } from 'src/app/core/models/firestore.model';
import { ErrorHandlerService, ErrorMessage, LogService, NotificationService } from 'src/app/core/services';
import { DailyEarningsService } from 'src/app/daily-earnings/services/daily-earnings.service';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { DashboardSelectors } from '.';
import { PartnerFirestoreService } from '../../services/partner-firestore.service';
import * as DashboardActions from './dashboard.actions';
import { DashboardTableFooterRow, DashboardTableRow } from './dashboard.reducer';
import { PartnerRevenueCsvExportModel } from "../../../shared/models/partner-revenue-csv-export.vm";
import { generateFileName, getHeaderMappingForInstance } from "../../../shared/utils/csv.utils";
import * as RevenueActions from "../../../driver/revenue/store/revenue.actions";
import { CsvDownloadService } from "../../../shared/services/csv-download.service";

@Injectable()
export class DashboardEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private partnerFirestoreService: PartnerFirestoreService,
    private dateHelperService: DateHelperService,
    private errorHandlerService: ErrorHandlerService,
    private dailyEarningsService: DailyEarningsService,
    private csvDownloadService: CsvDownloadService,
    private log: LogService,
    private notificationService: NotificationService,
  ) { }

  dashboardInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.dashboardInit),
      switchMap(() => {
        return merge(
          this.watchDataSourceParameters(),
          this.watchPartnerCompanyAggregate(),
          [DashboardActions.dateFilterChanged(this.dateHelperService.getDateRangeBack())] // init date filter
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(DashboardActions.dashboardDestroy)))
        )
      })
    );
  });

  rowExpandClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.rowExpandClicked),
      mergeMap((action) => {
        if (!action.row.isExpanded && !action.row.detailRows?.length) {
          return this.dailyEarningsService.getDetailRows(action.row).pipe(
            map((detailRows) => {
              return DashboardActions.detailRowsFetched({
                rowId: action.row.id,
                detailRows
              });
            })
          );
        } else {
          return [];
        }
      })
    )
  });

  downloadCsvClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.downloadCsvClicked),
      switchMap(() => {
        return this.store.select(DashboardSelectors.selectDataSourceParameters).pipe(
          take(1),
          switchMap((dataSourceParameters) => {
            return this.store.select(DashboardSelectors.selectPartnerCompanyId).pipe(
              filter(partnerCompanyId => !!partnerCompanyId),
              take(1),
              switchMap(partnerCompanyId => this.partnerFirestoreService.getDailyEarningsSplits(<string>partnerCompanyId, {
                  startDate: dataSourceParameters.startDate,
                  endDate: dataSourceParameters.endDate,
                  driverId: dataSourceParameters.driverId || null,
              })),
              take(1),
              switchMap(dailyEarnings =>
                this.store.select(DashboardSelectors.selectDriverAggregates).pipe(
                  filter(driverAggregates => !!driverAggregates),
                  take(1),
                  map(driverAggregates => <DriverAggregate[]>driverAggregates),
                  map(driverAggregates => {
                    return Array.from(dailyEarnings).map(([dailyEarningId, dailyEarning]) => {
                      if (!dailyEarning) {
                        throw new Error(`Daily earning ${dailyEarningId} not found. If the problem persists, please contact support.`);
                      }
                      return new PartnerRevenueCsvExportModel(dailyEarning, driverAggregates, this.dateHelperService);
                    })
                  }),
                  tap(csvModels => {
                    if (csvModels !== null) {
                      const headers = getHeaderMappingForInstance(csvModels[0]);
                      const fileName = generateFileName(this.dateHelperService, 'partner_revenue', dataSourceParameters.startDate, dataSourceParameters.endDate);
                      const sortedModels= csvModels.sort((a, b) => b.localDate.localeCompare(a.localDate) || a.driver.localeCompare(b.driver));
                      this.csvDownloadService.download(fileName, sortedModels, headers);
                    } else {
                      throw new Error('No data to download.');
                    }
                  }),
                  map(() => RevenueActions.downloadSuccess()),
                  catchError((error) => {
                    this.log.error(error);
                    this.notificationService.error(error, {
                      action: 'Close',
                      defaultMessage: 'CSV download failed. If the problem persists, please contact support.'
                    });
                    return [RevenueActions.downloadFailed()];
                  })
                )
              )
            );
          }),
          this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
        );
      })
    )
  });

  private watchDataSourceParameters(): Observable<Action> {
    return this.store.select(DashboardSelectors.selectDataSourceParameters).pipe(
      switchMap((dataSourceParameters) => {
        return this.store.select(DashboardSelectors.selectPartnerCompanyId).pipe(
          filter(partnerCompanyId => !!partnerCompanyId),
          take(1),
          concatLatestFrom(() => this.store.select(DashboardSelectors.selectTableRows)),
          switchMap(([partnerCompanyId, currentTableRows]) => {
            return combineLatest([
              this.partnerFirestoreService.listDailyEarningsSplits(<string>partnerCompanyId, {
                startDate: dataSourceParameters.startDate,
                endDate: dataSourceParameters.endDate,
                driverId: dataSourceParameters.driverId || null,
              }, {
                pageSize: dataSourceParameters.pageSize,
                previousPageIndex: dataSourceParameters.previousPageIndex,
                pageIndex: dataSourceParameters.pageIndex,
                firstDocumentId: currentTableRows && currentTableRows.length > 0 ? currentTableRows[0].id : null,
                lastDocumentId: currentTableRows && currentTableRows.length > 0 ? currentTableRows[currentTableRows.length - 1].id : null
              }),
              this.store.select(DashboardSelectors.selectPartnerCompanyArea).pipe(
                filter(area => !!area)
              )
            ])
          }),
          take(1),
          switchMap(([dailyEarnings, area]) =>
            this.store.select(DashboardSelectors.selectDriverAggregates).pipe(
              filter(driverAggregates => !!driverAggregates),
              take(1),
              map(driverAggregates => <DriverAggregate[]>driverAggregates),
              map(driverAggregates => this.getRows(dailyEarnings.pageRows, driverAggregates, area)),
              map(rows => DashboardActions.dataChanged({ rows: rows.rows, footer: rows.footer, totalCount: dailyEarnings.totalCount }))
            )
          )
        );
      }),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private watchPartnerCompanyAggregate(): Observable<Action> {
    return this.store.select(DashboardSelectors.selectPartnerCompanyAggregate).pipe(
      filter(partnerCompanyAggregate => !!partnerCompanyAggregate),
      switchMap((partnerCompanyAggregate) => {
        return this.partnerFirestoreService.watchDriverAggregates((<PartnerCompanyAggregate>partnerCompanyAggregate).daily_earnings_drivers);
      }),
      mergeMap(driverAggregates => [DashboardActions.driverAggregatesChanged({ driverAggregates })]),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private getRows(dailyEarnings: DailyEarningsSplit[], driverAggregates: DriverAggregate[], area?: Area): { rows: DashboardTableRow[], footer: DashboardTableFooterRow | null } {
    const driversMap = new Map<string, Driver>(driverAggregates.map(driverAgg => [driverAgg.id, driverAgg.driver]));
    const rows = this.dailyEarningsService.getRows(dailyEarnings, (split, row) => {
      const driver = driversMap.get(split.driver_id);
      return <DashboardTableRow>{
        ...row,
        driver: `${driver?.first_name} ${driver?.last_name}`
      }
    }, area);
    const footer = rows.footer
      ? {
        ...rows.footer,
        driver: rows.rows.reduce((acc, curr) => acc.add(curr.driver), new Set<string>()).size.toString()
      }
      : null;

    return {
      rows: rows.rows,
      footer
    };
  }
}
