import { DailyEarningsSplit, PartnerCompanyAggregate } from "../../core/models/firestore.model";
import { HeaderName } from "../decorators/header-name.decorator";
import { DateHelperService } from "../services/date-helper.service";
import { toFixedBankers } from "../utils/math-utils";

export class DriverRevenueCsvExportModel {
  @HeaderName('Week')
  localStartWeek: string;

  @HeaderName('Day')
  localDate: string;

  @HeaderName('Company')
  company: string;

  @HeaderName('Total cash received')
  totalReceivedCashAmount: string;

  @HeaderName('Cash revenue')
  cashRevenue: string;

  @HeaderName('App revenue')
  appRevenue: string;

  @HeaderName('POS revenue')
  posRevenue: string;

  @HeaderName('Invoice revenue')
  invoiceRevenue: string;

  @HeaderName('Other revenue')
  otherRevenue: string;

  @HeaderName('Revenue')
  netRevenue: string;

  @HeaderName('Adjustments')
  adjustments: string;

  @HeaderName('Tips')
  tips: string;

  @HeaderName('External fees (cash)')
  externalFeesCashAmount: string;

  @HeaderName('External fees (excluding cash)')
  externalFeesNonCashAmount: string;

  @HeaderName('TMS visits to be paid')
  tmsVisitsToBePaid: string;

  @HeaderName('Total external fees')
  externalFees: string;

  @HeaderName('Total')
  total: string;

  constructor(dailyEarningsSplit: DailyEarningsSplit, partnerCompanyAggregates: PartnerCompanyAggregate[], dateHelper: DateHelperService) {
    this.localStartWeek = dateHelper.getISOWeekNumber(dailyEarningsSplit.local_date);
    this.localDate = dateHelper.formatLocalDate(dailyEarningsSplit.local_date);
    this.company = this.companyName(partnerCompanyAggregates, dailyEarningsSplit);
    this.totalReceivedCashAmount = toFixedBankers(dailyEarningsSplit.received_cash_amount.value, 2);
    this.cashRevenue = toFixedBankers(dailyEarningsSplit.cash_revenue.value, 2);
    this.appRevenue = toFixedBankers(dailyEarningsSplit.app_revenue.value, 2);
    this.posRevenue = toFixedBankers(dailyEarningsSplit.pos_revenue.value, 2);
    this.invoiceRevenue = toFixedBankers(dailyEarningsSplit.invoice_revenue.value, 2);
    this.otherRevenue = toFixedBankers(dailyEarningsSplit.other_revenue.value, 2);
    this.netRevenue = toFixedBankers(dailyEarningsSplit.net_revenue.value, 2);
    this.adjustments = toFixedBankers(dailyEarningsSplit.adjustment_amount.value, 2);
    this.tips = toFixedBankers(dailyEarningsSplit.tip_amount.value, 2);
    this.externalFeesCashAmount = toFixedBankers(dailyEarningsSplit.external_fees_cash_amount.value, 2);
    this.externalFeesNonCashAmount = toFixedBankers(dailyEarningsSplit.external_fees_non_cash_amount.value, 2);
    this.tmsVisitsToBePaid = toFixedBankers(dailyEarningsSplit.tms_visit_amount.value, 2);
    this.externalFees = toFixedBankers(dailyEarningsSplit.external_fees_amount.value, 2);
    this.total = toFixedBankers(dailyEarningsSplit.total_amount.value, 2);
  }

  private companyName(partnerCompanyAggregates: PartnerCompanyAggregate[], dailyEarningsSplit: DailyEarningsSplit): string {
    const companyName = partnerCompanyAggregates.find(pca => pca.id === dailyEarningsSplit.partner_company_id)?.company?.name;
    if (!companyName) {
      throw new Error(`Company name not found for partner company id ${dailyEarningsSplit.partner_company_id}`);
    }
    return companyName;
  }
}
