import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from "@ngrx/store";
import { Observable, catchError, filter, map, merge, mergeMap, switchMap, take, takeUntil, tap, throwError } from "rxjs";
import { Area, DriverAggregate, PartnerCompanyAggregate, Shift, Vehicle } from "src/app/core/models/firestore.model";
import { ErrorHandlerService, ErrorMessage, LogService, NotificationService } from "src/app/core/services";
import { PartnerShiftsCsvExportModel } from "src/app/shared/models/partner-shifts-csv-export.vm";
import { CsvDownloadService } from "src/app/shared/services/csv-download.service";
import { DateHelperService } from "src/app/shared/services/date-helper.service";
import { generateFileName, getHeaderMappingForInstance } from "src/app/shared/utils/csv.utils";
import { ShiftTable, ShiftTableFooterRow, ShiftTableRow } from "src/app/shifts/models/shift-table";
import { PartnerShiftActions, PartnerShiftSelectors } from '.';
import { PartnerFirestoreService } from "../../services/partner-firestore.service";

@Injectable()
export class PartnerShiftEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private partnerFirestoreService: PartnerFirestoreService,
    private errorHandlerService: ErrorHandlerService,
    private dateHelperService: DateHelperService,
    private csvDownloadService: CsvDownloadService,
    private log: LogService,
    private notificationService: NotificationService
  ) { }

  initPartnerShifts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartnerShiftActions.shiftsInit),
      switchMap(() => {
        return merge(
          this.watchDataSourceParameters(),
          this.watchPartnerCompanyAggregate(),
          [PartnerShiftActions.shiftsDateRangeChanged(this.dateHelperService.getDateRangeBack())]
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(PartnerShiftActions.shiftsDestroy)))
        )
      })
    );
  });

  downloadCsvClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartnerShiftActions.downloadCsvClicked),
      switchMap(() => {
        return this.store.select(PartnerShiftSelectors.selectDataSourceParameters).pipe(
          take(1),
          switchMap((dataSourceParameters) => {
            return this.store.select(PartnerShiftSelectors.selectPartnerCompanyId).pipe(
              filter(partnerCompanyId => !!partnerCompanyId),
              take(1),
              switchMap(partnerCompanyId => this.partnerFirestoreService.getPartnerCompanyShifts(<string>partnerCompanyId, {
                startDate: dataSourceParameters.startDate,
                endDate: dataSourceParameters.endDate,
                driverIds: dataSourceParameters.driverIds || null,
              })),
              take(1),
              switchMap(shifts =>
                this.store.select(PartnerShiftSelectors.selectDriverAggregates).pipe(
                  filter((driverAggregates): driverAggregates is DriverAggregate[] => !!driverAggregates),
                  take(1),
                  switchMap(driverAggregates => {
                    const vehicleIds = [...new Set(Array.from(shifts.values()).map(shift => shift?.vehicle_id))].filter(id => id !== undefined) as string[];
                    return this.partnerFirestoreService.getVehicles(vehicleIds).pipe(
                      map(vehicles => {
                        const vehicleArray: Vehicle[] = Array.from(vehicles.entries()).map(([id, vehicle]) => ({ ...vehicle, id })).filter(vehicle => vehicle !== null) as Vehicle[];
                        return { driverAggregates, vehicles: vehicleArray };
                      }),
                      catchError(error => {
                        this.log.error(error);
                        this.notificationService.error(error, {
                          action: 'Close',
                          defaultMessage: 'Failed to load vehicles. If the problem persists, please contact support.'
                        });
                        return throwError(() => error);
                      }),
                    );
                  }),
                  map(({ driverAggregates, vehicles }) => {
                    return Array.from(shifts).map(([shiftId, shift]) => {
                      if (!shift) {
                        throw new Error(`Shift ${shiftId} not found. If the problem persists, please contact support.`);
                      }
                      return new PartnerShiftsCsvExportModel(shift, driverAggregates, vehicles, this.dateHelperService);
                    })
                  }),
                  tap(csvModels => {
                    if (csvModels !== null) {
                      const headers = getHeaderMappingForInstance(csvModels[0]);
                      const fileName = generateFileName(this.dateHelperService, 'partner_shifts', dataSourceParameters.startDate, dataSourceParameters.endDate);
                      const sortedModels = csvModels.sort((a, b) => b.startedAt?.localeCompare(a.startedAt) || a.driver.localeCompare(b.driver));
                      this.csvDownloadService.download(fileName, sortedModels, headers);
                    } else {
                      throw new Error('No data to download.');
                    }
                  }),
                  map(() => PartnerShiftActions.downloadSuccess()),
                  catchError((error) => {
                    this.log.error(error);
                    this.notificationService.error(error, {
                      action: 'Close',
                      defaultMessage: 'CSV download failed. If the problem persists, please contact support.'
                    });
                    return [PartnerShiftActions.downloadFailed()];
                  })
                )
              )
            );
          }),
          this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
        );
      })
    )
  });

  private watchDataSourceParameters(): Observable<Action> {
    return this.store.select(PartnerShiftSelectors.selectDataSourceParameters).pipe(
      switchMap((dataSourceParameters) => {
        return this.store.select(PartnerShiftSelectors.selectPartnerCompanyId).pipe(
          filter(partnerCompanyId => !!partnerCompanyId),
          take(1),
          concatLatestFrom(() => this.store.select(PartnerShiftSelectors.selectTableVM)),
          switchMap(([partnerCompanyId, currentTableVM]) => {
            return this.partnerFirestoreService.watchPartnerCompanyShifts(<string>partnerCompanyId, {
              startDate: dataSourceParameters.startDate,
              endDate: dataSourceParameters.endDate,
              driverIds: dataSourceParameters.driverIds
            }, {
              pageSize: dataSourceParameters.pageSize,
              previousPageIndex: dataSourceParameters.previousPageIndex,
              pageIndex: dataSourceParameters.pageIndex,
              firstDocumentId: currentTableVM?.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[0].id : null,
              lastDocumentId: currentTableVM?.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[currentTableVM.rows.length - 1].id : null
            }
            );
          }),
          switchMap((shiftsPagedData) => {
            if (!shiftsPagedData.totalCount) {
              return [PartnerShiftActions.dataChanged({ tableVM: this.buildTableVM([], [], undefined, new Map()), totalCount: 0 })];
            }
            return this.store.select(PartnerShiftSelectors.selectPartnerCompanyArea).pipe(
              filter(area => !!area),
              take(1),
              switchMap(area => this.store.select(PartnerShiftSelectors.selectDriverAggregates).pipe(
                filter(drivers => !!drivers),
                take(1),
                switchMap(drivers => {
                  const vehicleIds = [...new Set(shiftsPagedData.pageRows.map(shift => shift.vehicle_id))];
                  return this.partnerFirestoreService.getVehicles(vehicleIds).pipe(
                    map(vehicles => PartnerShiftActions.dataChanged({
                      tableVM: this.buildTableVM(shiftsPagedData.pageRows, drivers, area, vehicles),
                      totalCount: shiftsPagedData.totalCount,
                    }))
                  );
                })
              ))
            );
          }),
        );
      }),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private watchPartnerCompanyAggregate(): Observable<Action> {
    return this.store.select(PartnerShiftSelectors.selectPartnerCompanyAggregate).pipe(
      filter(partnerCompanyAggregate => !!partnerCompanyAggregate),
      switchMap((partnerCompanyAggregate) => {
        return this.partnerFirestoreService.watchDriverAggregates((<PartnerCompanyAggregate>partnerCompanyAggregate).daily_earnings_drivers);
      }),
      mergeMap(driverAggregates => [PartnerShiftActions.driverAggregatesChanged({ driverAggregates })]),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private buildTableVM(shifts: Shift[], drivers?: DriverAggregate[], area?: Area, vehicles?: Map<string, Vehicle | null>): ShiftTable {
    if (shifts?.length === 0) {
      return { rows: [], footer: null };
    }
    const rows = shifts.map(shift => {
      return <ShiftTableRow>{
        id: shift.id,
        startedAt: shift.started_at && { date: this.dateHelperService.format(shift.started_at), time: this.dateHelperService.formatTime(shift.started_at) },
        endedAt: shift.ended_at && { date: this.dateHelperService.format(shift.ended_at), time: this.dateHelperService.formatTime(shift.ended_at) },
        driver: drivers && this.findDriverNameAndPhoto(drivers, shift.driver_id),
        vehicle: shift.vehicle_id && vehicles && vehicles.get(shift.vehicle_id)?.license_plate,
        distance: shift.total_distance,
        occupied: shift.active_distance,
        control: shift.control_distance,
        active: shift.active_duration,
        passive: shift.inactive_duration,
        cash: shift.cash_revenue.display,
        revenue: shift.partner_gross_revenue.display,
      };
    });
    const footerTotals = shifts.reduce((acc, shift) => {
      acc.distance += +shift.total_distance;
      acc.occupied += +shift.active_distance;
      acc.control += +shift.control_distance;
      acc.active += +shift.active_duration;
      acc.passive += +shift.inactive_duration;
      acc.cash += shift.cash_revenue.value;
      acc.revenue += shift.partner_gross_revenue.value;
      return acc;
    },
      {
        distance: 0,
        occupied: 0,
        control: 0,
        active: 0,
        passive: 0,
        cash: 0,
        revenue: 0
      });

    const currencyFormat = new Intl.NumberFormat(area?.language_code, { style: 'currency', currency: shifts[0].partner_gross_revenue.currency });
    const footer: ShiftTableFooterRow = {
      ...footerTotals,
      cash: currencyFormat.format(footerTotals.cash),
      revenue: currencyFormat.format(footerTotals.revenue)
    };

    return { rows, footer };
  }

  private findDriverNameAndPhoto(drivers: DriverAggregate[], driverId: string) {
    const driverAggregate = drivers.find(driver => driver.id === driverId);
    if (!driverAggregate) {
      return { name: '', photoUrl: '' };
    }

    const { driver, photo } = driverAggregate;

    if (!driver) {
      return { name: '', photoUrl: '' };
    }

    return {
      name: `${driver.first_name} ${driver.last_name}`,
      photoUrl: photo?.url
    };
  }
}