import { Settlement } from "../../core/models/firestore.model";
import { HeaderName } from "../decorators/header-name.decorator";
import { DateHelperService } from "../services/date-helper.service";
import { toFixedBankers } from "../utils/math-utils";

export class SettlementCsvExportModel {

  @HeaderName('Creation date')
  creationDate: string;

  @HeaderName('Week')
  localStartWeek: string;

  @HeaderName('Period')
  period: string;

  @HeaderName('Cash revenue')
  cashRevenue: string;

  @HeaderName('App revenue')
  appRevenue: string;

  @HeaderName('POS revenue')
  posRevenue: string;

  @HeaderName('Invoice revenue')
  invoiceRevenue: string;

  @HeaderName('Other revenue')
  otherRevenue: string;

  @HeaderName('Total revenue')
  totalRevenue: string;

  @HeaderName('Service fee')
  serviceFee: string;

  @HeaderName('Total net')
  totalNet: string;

  @HeaderName('Cash deduction')
  cashDeduction: string;

  @HeaderName('External fees (excluding cash)')
  externalFeesNonCashAmount?: string;

  @HeaderName('TMS visits to be paid')
  tmsVisitsToBePaid: string;

  @HeaderName('Tips')
  tips: string;

  @HeaderName('Insurance - Vehicle insurance')
  vehicleInsurance: string;

  @HeaderName('Insurance - Business Liability Insurance')
  businessLiabilityInsurance: string;

  @HeaderName('Insurance - Occupational Insurance')
  occupationalInsurance: string;

  @HeaderName('Adjustments')
  adjustments: string;

  @HeaderName('Total settlement')
  settlementAmount: string;

  @HeaderName('Debt (from previous period)')
  debtFromPreviousPeriod: string;

  @HeaderName('Payout')
  payoutAmount: string;

  constructor(settlement: Settlement, dateHelper: DateHelperService) {
    this.creationDate = dateHelper.format(settlement.created_at);
    this.localStartWeek = settlement.local_start_week.toString().slice(4);
    this.period = `${dateHelper.formatLocalDate(settlement.local_start_date)} - ${dateHelper.formatLocalDate(settlement.local_end_date)}`;
    this.cashRevenue = toFixedBankers(settlement.cash_revenue.value, 2);
    this.appRevenue = toFixedBankers(settlement.app_revenue.value, 2);
    this.posRevenue = toFixedBankers(settlement.pos_revenue.value, 2);
    this.invoiceRevenue = toFixedBankers(settlement.invoice_revenue.value, 2);
    this.otherRevenue = toFixedBankers(settlement.other_revenue.value, 2);
    this.totalRevenue = toFixedBankers(settlement.net_revenue.value, 2);
    this.serviceFee = toFixedBankers(settlement.organization_service_fee_amount.value * -1, 2);
    this.totalNet = toFixedBankers(settlement.earnings_amount.value, 2);
    this.cashDeduction = toFixedBankers(settlement.deductible_cash_amount.value * -1, 2); // NEGATIVE
    this.externalFeesNonCashAmount = toFixedBankers(settlement.external_fees_non_cash_amount.value, 2);
    this.tmsVisitsToBePaid = toFixedBankers(settlement.tms_visit_amount.value, 2);
    this.tips = toFixedBankers(settlement.tip_amount.value, 2);
    this.vehicleInsurance = toFixedBankers(settlement.vehicle_insurance_amount.value, 2);
    this.businessLiabilityInsurance = toFixedBankers(settlement.business_liability_insurance_amount.value, 2);
    this.occupationalInsurance = toFixedBankers(settlement.occupational_insurance_amount.value, 2);
    this.adjustments = toFixedBankers(settlement.adjustment_amount.value, 2);
    this.settlementAmount = toFixedBankers(settlement.settlement_amount.value, 2);
    this.debtFromPreviousPeriod = toFixedBankers(settlement.period_start_debt_amount.value, 2);
    this.payoutAmount = toFixedBankers(settlement.payout_amount.value, 2);
  }
}
