import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs';
import { AuthActions, AuthSelectors } from 'src/app/auth/store';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(AuthSelectors.selectIsLoggedIn).pipe(
    filter(isLoggedIn => isLoggedIn != null),
    map(isLoggedIn => isLoggedIn as boolean),
    switchMap(isLoggedIn => {
      if (!isLoggedIn || route.queryParams['t']) {
        if (route.queryParams['t']) {
          if (isLoggedIn) {
            store.dispatch(AuthActions.loginWithQueryParam({ token: route.queryParams['t'], route: state.url.split('?')[0], reLogin: true }));
          } else {
            store.dispatch(AuthActions.loginWithQueryParam({ token: route.queryParams['t'], route: state.url.split('?')[0] }));
          }
          return [false];
        } else {
          return [router.createUrlTree(['/auth'])];
        }
      }
      return [true];
    }),
    take(1));
}
