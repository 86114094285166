import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IntercomEffects } from './intercom.effects';
import * as fromIntercom from './intercom.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromIntercom.intercomFeatureKey, fromIntercom.reducer),
    EffectsModule.forFeature([IntercomEffects])
  ]
})
export class IntercomStoreModule { }
