import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthStoreModule } from './auth/auth-store.module';
import { DriverStoreModule } from './driver/driver-store.module';
import { HomeStoreModule } from './home/home-store.module';
import { IntercomStoreModule } from './intercom/store/intercom-store.module';
import { PartnerShiftsStoreModule } from './partner/partner-shifts/store/partner-shifts-store.module';
import { PartnerStoreModule } from './partner/partner-store.module';
import { AppEffects } from './store';
import { appFeatureKey, reducer } from './store/app.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    StoreModule.forFeature(appFeatureKey, reducer),
    EffectsModule.forRoot(),
    EffectsModule.forFeature([AppEffects]),
    AuthStoreModule,
    HomeStoreModule,
    PartnerStoreModule,
    DriverStoreModule,
    IntercomStoreModule,
    PartnerShiftsStoreModule
  ]
})
export class AppStoreModule { }
