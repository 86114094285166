import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    Intercom: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

@Injectable()
export class IntercomService {
  get Intercom() {
    return window.Intercom;
  }

  boot(userId: string, userHash: string) {
    this.Intercom('boot', {
      app_id: environment.intercomAppId,
      user_id: userId,
      user_hash: userHash
    });
  }

  showNewMessages() {
    this.Intercom('showNewMessage');
  }

  shutdown() {
    this.Intercom('shutdown');
  }
}
