import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, from, merge, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Settlement } from 'src/app/core/models/firestore.model';
import { ErrorHandlerService, ErrorMessage, LogService } from 'src/app/core/services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { FirebaseStorageService } from 'src/app/shared/services/firebase-storage.service';
import { SettlementsSelectors } from '.';
import { PartnerFirestoreService } from '../../services/partner-firestore.service';
import * as SettlementsActions from './settlements.actions';
import { SettlementsTableRow, SettlementsTableVM } from './settlements.reducer';
import { CsvDownloadService } from "../../../shared/services/csv-download.service";
import { SettlementCsvExportModel } from "../../../shared/models/settlement-csv-export.vm";
import { generateFileName, getHeaderMappingForInstance } from "../../../shared/utils/csv.utils";

@Injectable()
export class SettlementsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private firebaseStorage: FirebaseStorageService,
    private partnerFirestoreService: PartnerFirestoreService,
    private dateHelperService: DateHelperService,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private log: LogService,
    private csvDownloadService: CsvDownloadService,
  ) { }

  initSettlements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettlementsActions.settlementsInit),
      switchMap(() => {
        return merge(
          this.watchDataSourceParameters(),
          [SettlementsActions.dateFilterChanged(this.dateHelperService.getDateRangeBack(6))] // init date filter
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(SettlementsActions.settlementsDestroy)))
        )
      })
    );
  });

  singleSettlementDownloadCsvClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettlementsActions.singleSettlementDownloadCsvClicked),
      switchMap(({ settlementId }) => {
        return this.store.select(SettlementsSelectors.selectPartnerCompanyId).pipe(
          filter(partnerCompanyId => !!partnerCompanyId),
          take(1),
          mergeMap(partnerCompanyId => this.partnerFirestoreService.getPartnerSettlement(<string>partnerCompanyId, settlementId)),
          map(settlement => {
            if (!settlement) {
              throw new Error(`Settlement ${settlementId} not found. If the problem persists, please contact support.`);
            }
            const fileName = generateFileName(
              this.dateHelperService,
              'settlement',
              this.dateHelperService.formatLocalDate(settlement.local_start_date),
              this.dateHelperService.formatLocalDate(settlement.local_end_date)
            );
            const csvModel = settlement ? new SettlementCsvExportModel(settlement, this.dateHelperService) : null;
            return { csvModel, fileName };
          }),
          tap(({csvModel, fileName}) => {
            if (csvModel !== null) {
              const headers = getHeaderMappingForInstance(csvModel);

              this.csvDownloadService.download(fileName, [csvModel], headers);
            } else {
              throw new Error('No data to download.');
            }
          }),
          map(() => SettlementsActions.downloadSuccess()),
          catchError((error) => {
            this.log.error(error);
            this.notificationService.error(error, {
              action: 'Close',
              defaultMessage: 'CSV download failed. If the problem persists, please contact support.'
            });
            return [SettlementsActions.downloadFailed()];
          })
        );
      })
    )
  });

  multipleSettlementsDownloadCsvClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettlementsActions.multipleSettlementsDownloadCsvClicked),
      switchMap(() => {
        return this.store.select(SettlementsSelectors.selectDataSourceParameters).pipe(
          take(1),
          switchMap((dataSourceParameters) => {
            return this.store.select(SettlementsSelectors.selectPartnerCompanyId).pipe(
              filter(partnerCompanyId => !!partnerCompanyId),
              take(1),
              switchMap(partnerCompanyId => this.partnerFirestoreService.getPartnerSettlements(<string>partnerCompanyId, {
                startDate: dataSourceParameters.startDate,
                endDate: dataSourceParameters.endDate
              })),
              take(1),
              map(settlements => {
                return Array.from(settlements).map(([settlementId, settlement]) => {
                  if (!settlement) {
                    throw new Error(`Settlement ${settlementId} not found. If the problem persists, please contact support.`);
                  }
                  return new SettlementCsvExportModel(settlement as Settlement, this.dateHelperService);
                })
              }),
              tap(csvModels => {
                if (csvModels !== null) {
                  const headers = getHeaderMappingForInstance(csvModels[0]);
                  const fileName = generateFileName(this.dateHelperService, 'settlement', dataSourceParameters.startDate, dataSourceParameters.endDate);
                  this.csvDownloadService.download(fileName, csvModels, headers);
                } else {
                  throw new Error('No data to download.');
                }
              }),
              map(() => SettlementsActions.downloadSuccess()),
              catchError((error) => {
                this.log.error(error);
                this.notificationService.error(error, {
                  action: 'Close',
                  defaultMessage: 'CSV download failed. If the problem persists, please contact support.'
                });
                return [SettlementsActions.downloadFailed()];
              })
            );
          })
        );
      })
    )
  });

  settlementDownloadClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettlementsActions.downloadClicked),
      switchMap(({ settlementId }) => {
        return this.store.select(SettlementsSelectors.selectPartnerCompanyId).pipe(
          filter(partnerCompanyId => !!partnerCompanyId),
          take(1),
          switchMap(partnerCompanyId => {
            return this.partnerFirestoreService.getSettlementCreditNote(<string>partnerCompanyId, settlementId).pipe(
              switchMap(creditNote => {
                return creditNote
                  ? from(this.firebaseStorage.getBlob(`partner_company_aggregate/${partnerCompanyId}/credit_note/${creditNote.id}/${creditNote.file_name}`)).pipe(
                    tap((blob) => {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = creditNote.file_name;
                      a.click();
                    })
                  )
                  : throwError(() => null);
              }),
            );
          }),
          map(() => SettlementsActions.downloadSuccess()),
          catchError((error) => {
            this.log.error(error);
            this.notificationService.error(error, {
              action: 'Close',
              defaultMessage: 'Credit note download failed. If the problem persists, please contact support.'
            });
            return [SettlementsActions.downloadFailed()];
          })
        );
      })
    );
  });

  private watchDataSourceParameters(): Observable<Action> {
    return this.store.select(SettlementsSelectors.selectDataSourceParameters).pipe(
      switchMap((dataSourceParameters) => {
        return this.store.select(SettlementsSelectors.selectPartnerCompanyId).pipe(
          filter(partnerCompanyId => !!partnerCompanyId),
          take(1),
          concatLatestFrom(() => this.store.select(SettlementsSelectors.selectTableVM)),
          switchMap(([partnerCompanyId, currentTableVM]) => {
            return this.partnerFirestoreService.watchPartnerCompanySettlements(<string>partnerCompanyId, {
              startDate: dataSourceParameters.startDate,
              endDate: dataSourceParameters.endDate
            }, {
              pageSize: dataSourceParameters.pageSize,
              previousPageIndex: dataSourceParameters.previousPageIndex,
              pageIndex: dataSourceParameters.pageIndex,
              firstDocumentId: currentTableVM.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[0].id : null,
              lastDocumentId: currentTableVM.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[currentTableVM.rows.length - 1].id : null
            }
            );
          }),
          map(settlementsPagedData => SettlementsActions.dataChanged({ tableVM: this.buildTableVM(settlementsPagedData.pageRows), totalCount: settlementsPagedData.totalCount }))
        );
      }),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private buildTableVM(settlements: Settlement[]): SettlementsTableVM {
    return {
      rows: settlements.map(settlement => {
        return <SettlementsTableRow>{
          id: settlement.id,
          created: this.dateHelperService.format(settlement.created_at),
          period: `${this.dateHelperService.formatLocalDate(settlement.local_start_date)} - ${this.dateHelperService.formatLocalDate(settlement.local_end_date)}`,
          payout: settlement.payout_amount.display,
          settlement: settlement.settlement_amount.display,
          totalRevenue: settlement.net_revenue.display
        };
      }),
      footer: null
    };
  }
}
