import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/auth/store';
import { ApiService } from 'src/app/core/services';
import { uuid } from 'src/app/shared/utils/uuid';
import { IntercomService } from '../services/intercom.service';

@Injectable()
export class IntercomEffects {
  constructor(
    private actions$: Actions,
    private intercomService: IntercomService,
    private store: Store,
    private api: ApiService
  ) { }

  userIdChanged$ = createEffect(() => {
    return this.store.select(AuthSelectors.selectUserId).pipe(
      switchMap(userId =>
        userId
          ? this.api.createUserIntercomHash({ idempotencyKey: uuid() }).pipe(
            tap(response => this.intercomService.boot(userId, response.userHash))
          )
          : of(this.intercomService.shutdown())
      ),
    );
  }, { dispatch: false });
}
