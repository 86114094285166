import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromHome from './store/home.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HomeEffects } from './store/home.effects';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromHome.homeFeatureKey, fromHome.reducer),
    EffectsModule.forFeature([HomeEffects])
  ]
})
export class HomeStoreModule { }
