import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver-es';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloadService {

  download<T>(filename: string, data: T[], headersMap: { [key: string]: string }): void {
    const csvData = this.convertToCSV(data, headersMap);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, filename + '.csv');
  }

  private convertToCSV<T>(data: T[], headersMap: { [key: string]: string }): string {
    const headers = Object.keys(headersMap).map(key => headersMap[key]).join(',');
    const csv = data.map(row =>
      Object.keys(headersMap).map(key => row[key as keyof T]).join(',')
    ).join('\n');

    return headers + '\n' + csv;
  }
}
