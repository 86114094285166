import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDasbhoard from './store/dashboard.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from './store/dashboard.effects';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromDasbhoard.dashboardFeatureKey, fromDasbhoard.reducer),
    EffectsModule.forFeature([DashboardEffects])
  ]
})
export class DashboardStoreModule { }
