import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Action, Store } from '@ngrx/store';
import { Observable, combineLatest, merge } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/auth/store';
import {
  Area,
  DailyEarningsSplit,
  DriverAggregate,
  PartnerCompany,
  PartnerCompanyAggregate
} from 'src/app/core/models/firestore.model';
import { ErrorHandlerService, ErrorMessage, LogService, NotificationService } from 'src/app/core/services';
import { DailyEarningsService } from 'src/app/daily-earnings/services/daily-earnings.service';
import { HomeSelectors } from 'src/app/home/store';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { RevenueSelectors } from '.';
import { DriverRevenueCsvExportModel } from "../../../shared/models/driver-revenue-csv-export.vm";
import { CsvDownloadService } from "../../../shared/services/csv-download.service";
import { generateFileName, getHeaderMappingForInstance } from "../../../shared/utils/csv.utils";
import { DriverFirestoreService } from '../../services/driver-firestore.service';
import * as RevenueActions from './revenue.actions';
import { RevenueTableFooterRow, RevenueTableRow } from './revenue.reducer';

@Injectable()
export class RevenueEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private driverFirestoreService: DriverFirestoreService,
    private dateHelperService: DateHelperService,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private dailyEarningsService: DailyEarningsService,
    private csvDownloadService: CsvDownloadService,
    private log: LogService,
  ) { }

  revenueInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RevenueActions.revenueInit),
      switchMap(() => {
        return merge(
          this.watchDataSourceParameters(),
          this.watchDriverAggregate(),
          [RevenueActions.dateFilterChanged(this.dateHelperService.getDateRangeBack())] // init date filter
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(RevenueActions.revenueDestroy)))
        )
      })
    );
  });

  rowExpandClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RevenueActions.rowExpandClicked),
      mergeMap((action) => {
        if (!action.row.isExpanded && !action.row.detailRows?.length) {
          return this.dailyEarningsService.getDetailRows(action.row).pipe(
            map((detailRows) => {
              return RevenueActions.detailRowsFetched({
                rowId: action.row.id,
                detailRows
              });
            })
          );
        } else {
          return [];
        }
      })
    )
  });

  downloadCsvClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RevenueActions.downloadCsvClicked),
      switchMap(() => {
        return this.store.select(RevenueSelectors.selectDataSourceParameters).pipe(
          take(1),
          switchMap((dataSourceParameters) => {
            return this.store.select(AuthSelectors.selectUserId).pipe(
              take(1),
              switchMap(driverId => this.driverFirestoreService.getDailyEarningsSplits(<string>driverId, {
                startDate: dataSourceParameters.startDate,
                endDate: dataSourceParameters.endDate,
                companyId: dataSourceParameters.companyId || null,
              })),
              take(1),
              switchMap(dailyEarnings => {
                return this.store.select(RevenueSelectors.selectPartnerCompanyAggregates).pipe(
                  filter(partnerCompanyAggregates => !!partnerCompanyAggregates),
                  take(1),
                  map(partnerCompanyAggregates => <PartnerCompanyAggregate[]>partnerCompanyAggregates),
                  map(partnerCompanyAggregates => {
                    return Array.from(dailyEarnings).map(([dailyEarningId, dailyEarning]) => {
                      if (!dailyEarning) {
                        throw new Error(`Daily earning ${dailyEarningId} not found. If the problem persists, please contact support.`);
                      }
                      return new DriverRevenueCsvExportModel(dailyEarning, partnerCompanyAggregates, this.dateHelperService);
                    })
                  }),
                  tap(csvModels => {
                    if (csvModels !== null) {
                      const headers = getHeaderMappingForInstance(csvModels[0]);
                      const fileName = generateFileName(this.dateHelperService, 'my_revenue', dataSourceParameters.startDate, dataSourceParameters.endDate);
                      const sortedModels = csvModels.sort((a, b) => b.localDate.localeCompare(a.localDate) || a.company.localeCompare(b.company));
                      this.csvDownloadService.download(fileName, sortedModels, headers);
                    } else {
                      throw new Error('No data to download.');
                    }
                  }),
                  map(() => RevenueActions.downloadSuccess()),
                  catchError((error) => {
                    this.log.error(error);
                    this.notificationService.error(error, {
                      action: 'Close',
                      defaultMessage: 'CSV download failed. If the problem persists, please contact support.'
                    });
                    return [RevenueActions.downloadFailed()];
                  })
                );
              })
            );
          }),
          this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
        );
      })
    )
  });

  private watchDataSourceParameters(): Observable<Action> {
    return this.store.select(RevenueSelectors.selectDataSourceParameters).pipe(
      switchMap((dataSourceParameters) => {
        return this.store.select(AuthSelectors.selectUserId).pipe(
          take(1),
          concatLatestFrom(() => this.store.select(RevenueSelectors.selectTableRows)),
          switchMap(([driverId, currentTableRows]) => {
            return combineLatest([
              this.driverFirestoreService.listDailyEarningsSplits(<string>driverId, {
                startDate: dataSourceParameters.startDate,
                endDate: dataSourceParameters.endDate,
                companyId: dataSourceParameters.companyId || null,
              }, {
                pageSize: dataSourceParameters.pageSize,
                previousPageIndex: dataSourceParameters.previousPageIndex,
                pageIndex: dataSourceParameters.pageIndex,
                firstDocumentId: currentTableRows && currentTableRows.length > 0 ? currentTableRows[0].id : null,
                lastDocumentId: currentTableRows && currentTableRows.length > 0 ? currentTableRows[currentTableRows.length - 1].id : null
              }),
              this.store.select(HomeSelectors.selectAreas).pipe(
                filter(areas => areas.length > 0)
              )
            ])
          }),
          take(1),
          switchMap(([dailyEarnings, areas]) => {
            return this.store.select(RevenueSelectors.selectPartnerCompanyAggregates).pipe(
              filter(partnerCompanyAggregates => !!partnerCompanyAggregates),
              take(1),
              map(partnerCompanyAggregates => <PartnerCompanyAggregate[]>partnerCompanyAggregates),
              map(partnerCompanyAggregates => this.getRows(dailyEarnings.pageRows, partnerCompanyAggregates, areas.find(area => area.id === partnerCompanyAggregates[0]?.company?.area_id))),
              map(rows => RevenueActions.dataChanged({ rows: rows.rows, footer: rows.footer, totalCount: dailyEarnings.totalCount }))
            );
          }),
        )
      }),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private watchDriverAggregate(): Observable<Action> {
    return this.store.select(AuthSelectors.selectDriverAggregate).pipe(
      filter(driverAggregate => !!driverAggregate),
      switchMap((driverAggregate) => {
        return this.driverFirestoreService.watchPartnerCompanyAggregates((<DriverAggregate>driverAggregate).daily_earnings_companies);
      }),
      mergeMap(partnerCompanyAggregates => [RevenueActions.partnerCompanyAggregatesChanged({ partnerCompanyAggregates })]),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private getRows(dailyEarnings: DailyEarningsSplit[], partnerCompanyAggregates: PartnerCompanyAggregate[], area?: Area): { rows: RevenueTableRow[], footer: RevenueTableFooterRow | null } {
    const partnerCompaniesMap = new Map<string, PartnerCompany>(partnerCompanyAggregates.map(partnerCompanyAgg => [partnerCompanyAgg.id, partnerCompanyAgg.company]));
    const rows = this.dailyEarningsService.getRows(dailyEarnings, (split, row) => {
      return <RevenueTableRow>{
        ...row,
        company: partnerCompaniesMap.get(split.partner_company_id)?.name
      }
    }, area);
    const footer = rows.footer
      ? {
        ...rows.footer,
        company: rows.rows.reduce((acc, curr) => acc.add(curr.company), new Set<string>()).size.toString()
      }
      : null;

    return {
      rows: rows.rows,
      footer
    };
  }
}
