// Basic metadata storage
export const METADATA_STORAGE: { [key: string]: { [key: string]: string } } = {};

export function HeaderName(name: string, isProduction = false) {
  if (isProduction) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => { };
  }
  return (target: object, propertyKey: string) => {
    const className = target.constructor.name;
    if (!METADATA_STORAGE[className]) {
      METADATA_STORAGE[className] = {};
    }
    METADATA_STORAGE[className][propertyKey] = name;
  };
}
