import { NgModule, inject } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs';
import { AuthSelectors } from './auth/store';
import { authGuard } from './core/guards/auth.guard';
import { driverOnboardingRouteGuard } from './driver/driver-onboarding/driver-onboarding-routing.module';

const homeRouteGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(AuthSelectors.selectDriverAggregate).pipe(
    filter(driverAggregate => driverAggregate !== undefined),
    switchMap(driverAggregate => {
      if (!driverAggregate?.driver && !!driverAggregate?.onboarding) {
        router.navigateByUrl('/driver-onboarding');
        return [false];
      } else {
        return [true];
      }
    }),
    take(1)
  )
}

const loginGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(AuthSelectors.selectIsLoggedIn).pipe(
    filter((isLoggedIn): isLoggedIn is boolean => isLoggedIn !== null),
    map((isLoggedIn: boolean) => isLoggedIn as boolean),
    switchMap(isLoggedIn => {
      if (isLoggedIn) {
        return [router.createUrlTree(['/home'])];
      }
      return [true];
    }),
    take(1));
}

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [loginGuard] },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [authGuard, homeRouteGuard], },
  {
    path: 'driver-onboarding',
    loadChildren: () => import('./driver/driver-onboarding/driver-onboarding.module').then(m => m.DriverOnboardingModule),
    canActivate: [authGuard, driverOnboardingRouteGuard],
  },
  {
    path: 'my-revenue',
    loadChildren: () => import('./driver/revenue/revenue.module').then(m => m.RevenueModule),
    canActivate: [authGuard],
  },
  { path: 'faq', loadChildren: () => import('./faq/faq.routes'), canActivate: [authGuard] },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
