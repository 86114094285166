import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/auth/store';
import { EffectsService, FirebaseAuthService } from 'src/app/core/services';
import * as HomeActions from './home.actions';

@Injectable()
export class HomeEffects {
  constructor(
    private actions$: Actions,
    private effectsService: EffectsService,
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private store: Store,
  ) {
    this.effectsService.addErrorHandler(this);
  }

  logoutButtonClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HomeActions.logoutButtonClicked),
      switchMap(() => this.firebaseAuthService.signOut()),
      switchMap(() => this.store.select(AuthSelectors.selectIsLoggedIn).pipe(
        filter(isLoggedIn => !isLoggedIn),
      )),
      switchMap(() => this.router.navigateByUrl('/auth')),
    );
  }, { dispatch: false });
}
