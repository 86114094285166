import { NgModule, isDevMode } from '@angular/core';
import { ScreenTrackingService, UserTrackingService, getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Locale, da, enUS, lt } from 'date-fns/locale';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { IntercomModule } from './intercom/intercom.module';
import { DATE_LOCALE } from './shared/services/date-helper.service';

// Supported locales
const Locales: { [key: string]: Locale } = {
  'da': da,
  'en-US': enUS,
  'lt': lt
};

function getSupportedLocale() {
  for (const lang of window.navigator.languages) {
    if (Locales[lang]) {
      return Locales[lang];
    }
  }
  return enUS;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppStoreModule,
    BrowserAnimationsModule,
    CoreModule,

    IntercomModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: getSupportedLocale()
    },
    {
      provide: DATE_LOCALE,
      useValue: getSupportedLocale()
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
