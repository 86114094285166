import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettlementsEffects } from './store/settlements.effects';
import * as fromSettlements from './store/settlements.reducer';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromSettlements.settlementsFeatureKey, fromSettlements.reducer),
    EffectsModule.forFeature([SettlementsEffects]),
    SharedModule
  ]
})
export class SettlementsStoreModule { }
