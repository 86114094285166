import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ThemeService } from './core/services/theme.service';
import { AppActions } from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private _themeService: ThemeService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(AppActions.init());
  }

  ngOnDestroy() {
    this.store.dispatch(AppActions.appDestroy());
  }
}
