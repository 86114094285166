import { createFeature, createReducer } from '@ngrx/store';

export const intercomFeatureKey = 'intercom';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface State {

}

export const initialState: State = {

};

export const reducer = createReducer(
  initialState,
);

export const intercomFeature = createFeature({
  name: intercomFeatureKey,
  reducer,
});

