import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromRevenue from './revenue.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RevenueEffects } from './revenue.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRevenue.revenueFeatureKey, fromRevenue.reducer),
    EffectsModule.forFeature([RevenueEffects])
  ]
})
export class RevenueStoreModule { }
