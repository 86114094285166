import { DriverAggregate, Shift, Vehicle } from "src/app/core/models/firestore.model";
import { HeaderName } from "../decorators/header-name.decorator";
import { DateHelperService } from "../services/date-helper.service";
import { toFixedBankers } from "../utils/math-utils";

export class PartnerShiftsCsvExportModel {

    @HeaderName('Week')
    localStartWeek: string;

    @HeaderName('Started At')
    startedAt: string;

    @HeaderName('Ended At')
    endedAt: string;

    @HeaderName('Driver')
    driver: string;

    @HeaderName('Vehicle')
    vehicle: string;

    @HeaderName('Distance')
    distance: string;

    @HeaderName('Occupied')
    occupied: string;

    @HeaderName('Control')
    control: string;

    @HeaderName('Active')
    active: number;

    @HeaderName('Passive')
    passive: number;

    @HeaderName('Cash')
    cash: string;

    @HeaderName('Revenue')
    revenue: string;

    constructor(shift: Shift, driverAggregates: DriverAggregate[], vehicles: Vehicle[], dateHelper: DateHelperService) {
        this.localStartWeek = shift.started_at ? dateHelper.getISOWeekNumber(dateHelper.parseDateStrToLocalDate(dateHelper.format(shift.started_at))) : '';
        this.startedAt = shift.started_at ? `${dateHelper.format(shift.started_at)} ${dateHelper.formatTime(shift.started_at)}` : '';
        this.endedAt = shift.ended_at ? `${dateHelper.format(shift.ended_at)} ${dateHelper.formatTime(shift.ended_at)}` : '';
        this.driver = this.driverName(shift, driverAggregates);
        this.vehicle = vehicles?.find(vehicle => vehicle.id === shift.vehicle_id)?.license_plate || shift.vehicle_id;
        this.distance = this.formatDistance(shift.total_distance);
        this.occupied = this.formatDistance(shift.active_distance);
        this.control = this.formatDistance(shift.control_distance);
        this.active = this.formatTime(shift.active_duration);
        this.passive = this.formatTime(shift.inactive_duration);
        this.cash = toFixedBankers(shift.cash_revenue.value, 2);
        this.revenue = toFixedBankers(shift.partner_gross_revenue.value, 2);
    }

    private driverName(shift: Shift, driverAggregates: DriverAggregate[]) {
        const driver = driverAggregates.find(driverAggregate => driverAggregate.id === shift.driver_id)?.driver;
        if (!driver) {
            throw new Error(`Driver name not found for id ${shift.driver_id}`);
        }
        return driver.first_name + ' ' + driver.last_name;
    }

    private formatDistance(distance: number) {
        return (distance / 1000).toFixed(2);
    }

    private formatTime(time: number) {
        return Math.floor(+time / 60);
    }
}