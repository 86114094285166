export function bankersRounding(value: number, decimalPlaces = 0): number {
  // Calculate scaling factor based on the desired decimal places
  const scaleFactor = Math.pow(10, decimalPlaces);

  // Adjust the value for potential floating-point inaccuracies and scale it
  const adjustedValue = +(decimalPlaces ? value * scaleFactor : value).toFixed(8);

  // Extract the integer and fractional parts of the adjusted value
  const integerPart = Math.floor(adjustedValue);
  const fractionalPart = adjustedValue - integerPart;

  // Define a small number to handle potential inaccuracies in floating-point operations
  const precisionEpsilon = 1e-8;

  let roundedResult;

  // Check if the fractional part is approximately 0.5 considering potential inaccuracies
  if (fractionalPart > 0.5 - precisionEpsilon && fractionalPart < 0.5 + precisionEpsilon) {
    // If integer part is even, keep it. If odd, increment by 1.
    roundedResult = (integerPart % 2 === 0) ? integerPart : integerPart + 1;
  } else {
    // Apply standard rounding if fractional part is not approximately 0.5
    roundedResult = Math.round(adjustedValue);
  }

  // Return the rounded result adjusted for the desired decimal places
  return decimalPlaces ? roundedResult / scaleFactor : roundedResult;
}

export function toFixedBankers(value: number, decimalPlaces: number): string {
  const rounded = bankersRounding(value, decimalPlaces);
  return rounded.toFixed(decimalPlaces);
}
