import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { from, merge, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DriverAggregate, Partner } from 'src/app/core/models/firestore.model';
import { ApiService, EffectsService, ErrorHandlerService, ErrorMessage, FirebaseAuthService } from 'src/app/core/services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { uuid } from 'src/app/shared/utils/uuid';
import { AuthSelectors } from '.';
import { AuthFirestoreService } from '../services/auth-firestore.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    effectsService: EffectsService,
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private notification: NotificationService,
    private api: ApiService,
    private firebaseAuth: FirebaseAuthService,
    private firestore: AuthFirestoreService,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService
  ) {
    effectsService.addErrorHandler(this);
  }

  watchUserAggregates$ = createEffect(() => {
    return this.store.select(AuthSelectors.selectUserId).pipe(
      switchMap((userId) => {
        return merge(
          this.watchDriverAggregate(userId),
          this.watchPartner(userId)
        )
      })
    );
  });

  loginButtonClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginButtonClicked),
      switchMap(action => {
        const impersonateMatches = action.password.match(/impersonate:(.+)/);
        return (impersonateMatches && impersonateMatches.length > 1
          ? of({
            token: impersonateMatches[1]
          })
          : this.api.signIn({
            email: action.email,
            password: action.password,
            idempotencyKey: uuid()
          })
        ).pipe(
          switchMap(response =>
            this.firebaseAuth.signInWithCustomToken(response.token)
          ),
          catchError(error => {
            this.notification.error(error, {
              action: 'Close',
              defaultMessage: 'Login failed. If the problem persists, please contact support.'
            });
            return of(null);
          })
        )
      }),
      switchMap(result =>
        result
          ? [AuthActions.loginSuccess()]
          : [AuthActions.loginFailed()]
      )
    )
  });

  loginWithQueryParam$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginWithQueryParam),
      switchMap(action => {
        if (action.reLogin) {
          return from(this.firebaseAuth.signOut()).pipe(
            switchMap(() => this.store.select(AuthSelectors.selectIsLoggedIn).pipe(
              filter(isLoggedIn => !isLoggedIn),
              switchMap(() => [action])
            )),
          );
        } else {
          return [action];
        }
      }),
      switchMap(action => {
        return from(this.firebaseAuth.signInWithCustomToken(action.token)).pipe(
          switchMap(response => [{ result: response, route: action.route }]),
          catchError(error => {
            this.notification.error(error, {
              action: 'Close',
              defaultMessage: 'Login failed. If the problem persists, please contact support.'
            });
            return [];
          }
          )
        )

      }),
      switchMap(({ result, route }) =>
        result
          ? [AuthActions.loginSuccess(route)]
          : [AuthActions.loginFailed()]
      )
    )
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(({ route }) => {
        return this.store.select(AuthSelectors.selectIsLoggedIn).pipe(
          filter(isLoggedIn => !!isLoggedIn),
          tap(() => {
            this.router.navigateByUrl(route || '/home');
          })
        );
      })
    )
  }, { dispatch: false });

  forgotPasswordButtonClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPasswordButtonClicked),
      tap(() => {
        this.router.navigateByUrl('/auth/forgot-password');
      })
    )
  }, { dispatch: false });

  passwordResetButtonClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.passwordResetButtonClicked),
      switchMap(action =>
        this.api.sendPasswordResetEmail({ email: action.email, idempotencyKey: uuid() }).pipe(
          catchError(error => {
            this.notification.error(error, {
              action: 'Close',
              defaultMessage: 'Password reset failed. If the problem persists, please contact support.'
            });
            return of(null);
          })
        )
      ),
      switchMap(result =>
        result
          ? [AuthActions.passwordResetSuccess()]
          : [AuthActions.passwordResetFailed()]
      )
    )
  });

  authStateChanged$ = createEffect(() => {
    return this.firebaseAuth.authState().pipe(
      switchMap(user => {
        return user
          ? from(this.firebaseAuth.getIdTokenResult(user)).pipe(
            map(idTokenResult => ({
              uid: user.uid,
              email: idTokenResult.claims['email']
            }))
          )
          : of(null)
      }),
      map(user => AuthActions.authStateChanged({ user }))
    )
  });

  signUpComponentSignUpClicked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signUpComponentSignUpClicked),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectReferrerPartnerCompanyId)),
      switchMap(([action, referrerPartnerId]) =>
        this.api.createUser({
          email: action.email,
          password: action.password,
          referrerPartnerId
        }).pipe(
          switchMap(() =>
            this.api.signIn({
              email: action.email,
              password: action.password,
              idempotencyKey: uuid()
            }).pipe(
              switchMap(response =>
                this.firebaseAuth.signInWithCustomToken(response.token)
              )
            )
          ),
          catchError(error => {
            console.error(error);
            this.notificationService.error(error);
            return of(null);
          })
        )
      ),
      switchMap(result => result
        ? this.firestore.watchDriverAggregate(result.user.uid).pipe(
          filter(driverAggregate => !!driverAggregate),
          take(1),
          switchMap(result => result ? this.router.navigateByUrl('/driver-onboarding') : of(null)),
          map(() => AuthActions.signUpFinished())
        )
        : [AuthActions.signUpFailed()])
    )
  });

  invitationInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.invitationInit),
      switchMap(action => {
        if (action.invitation) {
          const decodedInvitation = this.decodeInvitation(action.invitation);
          return decodedInvitation
            ? this.firestore.getPartnerCompanyAggregate(decodedInvitation.partnerId)
            : []
        } else {
          return [];
        }
      }),
      map(partnerCompanyAggregate => {
        return partnerCompanyAggregate?.company
          ? {
            id: partnerCompanyAggregate.id,
            name: partnerCompanyAggregate.company.name
          }
          : null;
      }),
      switchMap(partnerCompany => [AuthActions.referrerPartnerChanged({ partnerCompany })])
    )
  }
  );

  private watchPartner(userId: string | null | undefined) {
    return (userId
      ? this.firestore.watchPartner(userId)
      : of(<Partner | null | undefined>undefined)
    ).pipe(
      switchMap(partner => {
        return merge(
          of(AuthActions.partnerChanged({ partner })),
          ...(
            partner?.company_ids?.length
              ? [
                this.firestore.watchPartnerCompanyAggregates(partner.company_ids).pipe(
                  map(partnerCompanyAggregates => AuthActions.partnerCompanyAggregatesChanged({ partnerCompanyAggregates }))
                )
              ]
              : []
          )
        );
      }),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private watchDriverAggregate(userId: string | null | undefined) {
    return (userId
      ? this.firestore.watchDriverAggregate(userId)
      : of(<DriverAggregate | null | undefined>undefined)
    ).pipe(
      map(driverAggregate => AuthActions.driverAggregateChanged({ driverAggregate })),
      this.errorHandlerService.catchErrorAndRetry(ErrorMessage.Unknown)
    );
  }

  private decodeInvitation(invitation: string): { partnerId: string } | null {
    try {
      return JSON.parse(atob(invitation));
    } catch (error) {
      console.error(error);
      this.notificationService.error('Error decoding invitation. Please make sure the link url is correct.');
      return null;
    }
  }
}
