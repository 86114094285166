import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PartnerShiftEffects } from './partner-shifts.effects';
import { partnerShiftsReducer } from './partner-shifts.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('partnerShifts', partnerShiftsReducer),
    EffectsModule.forFeature([PartnerShiftEffects]),
  ]
})
export class PartnerShiftsStoreModule { }
