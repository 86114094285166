import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RevenueStoreModule } from './revenue/store/revenue-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RevenueStoreModule
  ]
})
export class DriverStoreModule { }
