import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsService, ErrorHandlerService, FirestoreService, LocalStorageService, LogService, NotificationService, PersistentLoggerService } from './services';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    LogService,
    PersistentLoggerService,
    LocalStorageService,
    ErrorHandlerService,
    EffectsService,
    NotificationService,
    FirestoreService
  ]
})
export class CoreModule { }
